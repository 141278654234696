// mirrors gatsby-ssr

import React from "react";
import { RecoilRoot } from "recoil";
import "tailwindcss/dist/base.min.css";
import "./global.css";

export const wrapPageElement = ({ element, props }) => {
    return <RecoilRoot {...props}>{element}</RecoilRoot>;
};
