// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-discover-tsx": () => import("./../../../src/pages/discover.tsx" /* webpackChunkName: "component---src-pages-discover-tsx" */),
  "component---src-pages-form-test-tsx": () => import("./../../../src/pages/form-test.tsx" /* webpackChunkName: "component---src-pages-form-test-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-made-to-measure-tsx": () => import("./../../../src/pages/made-to-measure.tsx" /* webpackChunkName: "component---src-pages-made-to-measure-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

