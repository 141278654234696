module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Open Weaves","short_name":"Open Weaves","description":"Perennial designs, world's finest yarns.","start_url":"/","background_color":"#fafafa","theme_color":"#425c7a","display":"standalone","icon":"./static/banner.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b6e209990fbb77d58800df5407541a8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179156994-1","anonymize":true,"respectDNT":true,"defer":true,"head":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
